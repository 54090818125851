/* 

-
Global 
-

*/


/* Variables */
:root
{
    --primary: #FFFFFF;
    --accent: #C2091F;
    --secondary: #757575;
    --auxiliary: #3B5F6F;
    --background: #343A40;
}

/* Font */
@font-face {
    font-family: "FuturaPT";
    src: url("./fonts/futura-pt-book.otf") format("opentype");
}

/* Page */
body
{
    margin: 0 auto;
    width: 100%;
    background-color: var(--background);
    color: var(--primary);
    overflow-x: hidden;
    font-family: "FuturaPT";
}

/* Root */
#root
{
    width: 100vw;
    display: block;
    margin: 0 auto;
    padding: 0;
    border: 0;
    overflow-x: hidden;
}

/* Main */
main
{
    width: 95vw;
    display: block;
    margin: 0 auto;
    padding: 0;
    border: 0;
}


/* 

-
Themes 
-

*/


/* Primary Theme */
.primary
{
    background: var(--primary);
    color: var(--accent);
}

/* Accent Theme */
.accent
{
    background: var(--accent);
    color: var(--primary);
}

/* Auxiliary Theme */
.auxiliary
{
    background: var(--auxiliary);
    color: var(--primary);
}

/* Alternative Theme */
.alternative
{
    background: var(--primary);
    color: var(--auxiliary);
}

/* Blended Theme */
.blend
{
    background: var(--background);
    color: var(--primary);
}

/* Extra Space */
.space
{
    margin-inline: 5px;
}

/* 

-
Essentials 
-

*/


/* Header */
.top
{
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-left: 0;
    padding-right: 0;
    content: "";
    display: block;
    max-width: 100%;
    margin: 0 auto;
    border: 0;
    text-align: center;
    background: url(../../../public/assets/images/banners/team-coaching.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #343A4066;
    background-blend-mode: multiply;
}

/* Footer */
.bottom
{
    max-width: 100%;
}

/* Section */
section
{
    display: grid;
    place-items: center;
    align-content: middle;
}


/* 

-
Typography 
-

*/


/* Title */
h1
{
    font-size: 60px;
}

/* Heading */
h2
{
    display: inline-block;
    font-size: 40px;
    word-wrap: break-word;
    word-break: break-word;
}

/* Hero */
h3
{
    font-size: 25px;
}

details 
{
    background-color: var(--accent);
    padding: 15px;
    border-radius: 25px;   
    margin-bottom: 25px;
}

details summary
{
    font-size: 30px;
    background-color: var(--accent);
    padding: 15px;
    border-radius: 25px;
    cursor: pointer;
}

details.info
{
    background-color: var(--primary);
    padding: 15px;
    border-radius: 25px;   
    margin-bottom: 25px;
    color: var(--accent);
}

details.info > p
{
    font-size: 18px;
    color: var(--accent);
}

details.info summary
{
    font-size: 30px;
    background-color: var(--primary);
    padding: 15px;
    border-radius: 25px;
    color: var(--accent);
}

details.info summary
{
    font-size: 30px;
    background-color: var(--primary);
    padding: 15px;
    border-radius: 25px;
    color: var(--accent);
}

details.info > hr
{
    background-color: var(--accent);
}

details > hr
{
    background-color: var(--primary);
}

/* Paragraph */
p
{
    font-size: 18px;
    color: var(--primary);
}

/* Description */
.description
{
    font-size: 18px;
    font-weight: bold;
    color: var(--primary);
}

/* Emphasis */
.emphasis
{
    color: var(--accent);
    font-size: 20px;
    margin: 0;
    font-weight: bold;
}

.link
{
    margin-left: 15px;
    font-size: 18px;
    font-weight: bold;
    color: var(--primary);
}


/* 

-
Structure 
-

*/


/* Rows for All Screen Sizes */
.row
{
    width: 100%;
    display: block;
}


/* Columns for Small Screen Sizes, e.g. Mobile, Tablet, etc. */
@media (max-width: 1000px)
{

    .column-10
    {
        width: 100%;
    }

    .column-20
    {
        width: 100%;
    }

    .column-25
    {
        width: 100%;
    }

    .column-30
    {
        width: 100%;
    }

    .column-40
    {
        width: 100%;
    }

    .column-50
    {
        width: 100%;
    }

    .column-60
    {
        width: 100%;
    }

    .column-70
    {
        width: 100%;
    }

    .column-75
    {
        width: 100%;
    }

    .column-80
    {
        width: 100%;
    }

    .column-90
    {
        width: 100%;
    }

    .column-100
    {
        width: 100%;
    }

}

/* Columns for Normal Screen Sizes, e.g. Desktop, Laptop, etc. */
@media (min-width: 1000px)
{
    
    .column-10
    {
        width: 9%;
        display: inline-block;
    }

    .column-20
    {
        width: 19%;
        display: inline-block;
    }

    .column-25
    {
        width: 24%;
        display: inline-block;
    }

    .column-30
    {
        width: 29%;
        display: inline-block;
    }

    .column-40
    {
        width: 39%;
        display: inline-block;
    }

    .column-50
    {
        width: 49%;
        display: inline-block;
    }

    .column-60
    {
        width: 59%;
        display: inline-block;
    }

    .column-70
    {
        width: 69%;
        display: inline-block;
    }

    .column-75
    {
        width: 74%;
        display: inline-block;
    }

    .column-80
    {
        width: 79%;
        display: inline-block;
    }

    .column-90
    {
        width: 89%;
        display: inline-block;
    }

    .column-100
    {
        width: 100%;
        display: inline-block;
    }

}

/* Column properties */
.left
{
    vertical-align: top;
    left: 0;
    margin-right: 0 auto;
    margin-left: 0;
    padding-right: 1%;
}

.right
{ 
    vertical-align: top;
    right: 0;
    margin-right: 0;
    margin-left: 0 auto;
    padding-left: 1%;
}


/* 

-
Elements 
-

*/


/* Curve */
.curve
{
    border-radius: 25px;
}

/* Banner */
.banner
{
    max-width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    margin: 0 auto;
    border: 0;
    overflow: hidden;
}

/* Button */
button, .btn
{
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 35px;
    padding-right: 35px;
    font-size: 18px;
    font-weight: bold;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    border-radius: 100px;
    border: 0;
}

.icon-container
{
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}

.icon-button
{
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    background-color: transparent;
    float: left;
    width: 19%;
    height: auto;
}

.icon-button img
{
    max-height: 100%;
    max-width: 100%;
}

/* Icons */
.icon
{
    height: 95px;
    width: auto;
}

/* Cards */
.card
{
    width: 100%;
    height: auto;
    border-radius: 25px;
    display: block;
    padding-inline: 2.5px;
    padding-top: 5px;
    padding-bottom: 5px;
    transform: translateY(25px);
}

/* Infographics */
.infographic
{
    height: auto;
    max-width: 95%;
    padding: 2.5%;
    margin: 0 auto;
    border-radius: 25px;
}

/* Spacer */
.spacer
{
    font-size: 25px;
}


/*

Forms

*/

.step-container
{
    text-align: left;
    margin-top: 20px;
}

.step
{
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: var(--accent);
    border: none;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.5;
}

.step.active
{
    opacity: 1;
}

.step.finish
{
    background-color: var(--background);
}

.tab
{
    display: none;
    margin-bottom: 50px;
}

.tab.active
{
    display: inline;
}

.first
{
    display: inline;
}

input[type="email"], input[type="number"], select, input[type="text"], input[type="password"], input[type="date"], textarea
{
    padding: 15px;
    padding-right: 5px;
    width: 90%;
    border-radius: 100px;
    font-size: 20px;
    background-color: var(--accent);
    margin-bottom: 15px;
    margin-top: 5px;
    color: var(--primary);
    outline-color: var(--primary);
}


td:has(select) {
    min-width: 100%;
}

select {
    width: 22ch;
}

label
{
    font-size: 25px;
    margin-right: 15px;
}

::placeholder
{
    color: var(--primary);
    opacity: 50%;
}

.scrollbar ::placeholder
{
    color: var(--accent);
}

input[type="checkbox"]
{
    height: 18px;
    width: 18px;
    margin-bottom: 15px;
    margin-right: 15px;
    margin-top: 5px;
    accent-color: var(--accent);
}
    
/* Submit */
input[type="submit"]
{
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 35px;
    padding-right: 35px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    border-radius: 100px;
    border: 0;
    display: none;
}

button:disabled, button[disabled], .btn[disabled]
{
    opacity: 50%;
}

/* Next */
.next
{
    display: inline;
}

/* Show */
.show
{
    display: inline;
}

.hide
{
    display: none;
}

/* 

Side bar

*/

/* The side navigation menu */
.sidebar {
    height: 100%; /* 100% Full-height */
    width: 0vw; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 5; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: var(--accent); /* Black*/
    padding-top: 25px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    overflow-x: hidden;
  }

.sidebar div *
{
    padding-left: 10px;
    padding-right: 10px;
}

/* Position and style the close button (top right corner) */
.sidebar .close {
    position: absolute;
    top: 25px;
    right: 25px;
    font-size: 50px;
    color: var(--primary);
    cursor: pointer;
  }
  
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidebar {padding-top: 15px;}
    .sidebar a {font-size: 18px;}
  }
  
.side_section
{
    outline-color: var(--primary);
    outline-width: 5px;
}


/* 

Sidebar Forms

*/

.big-gap {
    margin-inline-start: 0rem;
    margin-inline-end: 0rem;
}

.sidebar input[type="email"], .sidebar input[type="number"], .sidebar input[type="text"], .sidebar textarea, .sidebar input[type="password"], .sidebar input[type="date"], .sidebar select,
details input[type="email"], details input[type="number"], details input[type="text"], details input[type="password"], details textarea, details input[type="date"], details select
{
    padding: 10px;
    padding-right: 2.5px;
    width: 85.5%;
    border-radius: 100px;
    font-size: 15px;
    background-color: var(--primary);
    margin-bottom: 10px;
    margin-top: 3px;
    color: var(--background);
    outline-color: var(--background);
}

details textarea, .sidebar textarea, textarea {
    border-radius: 20px;
    font-family: FuturaPT;
}

.sidebar label, details ::placeholder
{
    font-size: 20px;
    margin-right: 15px;
}

.sidebar ::placeholder, details ::placeholder
{
    color: var(--background);
    font-size: 15px;
    opacity: 50%;
}

/* 

Scrollbar

*/

.scrollbar
{
    background-color: var(--accent);
    width: 100%;
    padding-top: 10px;
}

.scrollbar input
{
    margin-bottom: 25px;
    margin-left: 0px;
    width: 99%;
    background-color: var(--primary);
    color: var(--accent);
}

.scrollbar input ::placeholder
{
    color: var(--accent);
}

.completed {
    background-color: var(--auxiliary);
    color: var(--primary);
}

.started {
    background-color: var(--secondary);
}

.notstarted {
    background-color: var(--accent);
}

.missingdata {
    background-color: #B6D3EE;
}

.scrollbar .scroller
{
    padding-inline: 25px;
    gap: 25px;
    display: grid;
    grid-auto-flow: column;
    overflow-x: scroll;
    overscroll-behavior-inline: contain;
}

.scrollbar .scroller .scrollee
{
    width: 45ch;
    margin-bottom: 30px;
    padding: 1%;
    background-color: var(--primary);
    border-radius: 25px;
    text-align: center;
    margin-top: 5px;
}

.byebye {
    display: none;
}

.scrollbar .scroller .scrollee h3
{
    color: var(--accent);
    word-wrap: break-word;
    height: 95px;
    vertical-align: middle;
}

.scrollbar .scroller .scrollee p
{
    color: var(--background);
    text-align: center;
}

.scrollbar .scroller .scrollee button
{
    width: 100%;
}

/*

Table

*/

table
{
    display: block;
    overflow-x: auto;
    background-color: none;
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
    white-space: nowrap;
    max-width: 95vw;
    padding-bottom: 15px;
}

tbody {
    display: table-row-group;;
    width: 100%;
}

thead
{
    display: table-header-group;;
    width: 100%;
}

th
{
    font-size: 20px;
    border-bottom: 4px solid var(--primary);
    background-color: var(--accent);
    color: var(--primary);
    border: 1px solid var(--primary);
    padding-inline: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: bold;
    min-width: 200px;
    max-width: none;
}

td
{
    font-size: 20px;
    background-color: var(--background);
    color: var(--primary);
    border: 1px solid var(--primary);
    padding-inline: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: normal;
    min-width: 150px;
    max-width: none;
}

#add
{
    border-radius: 80%;
    display: block;
    background-color: var(--accent);
    color: var(--primary);
    position: fixed;
    top: 15px;
    left: 15px;
    border: 5px var(--primary);
    filter: drop-shadow(0px 0px 7.5px var(--primary));
    padding: 5px;
    width: 40px;
    height: 40px;
    font-size: 35px;
    text-align: center;
    z-index: 3;
    cursor: pointer;

}


input[type="file"]
{
    display: none;
}

.instructions {
    list-style-type: "✓    ";
}

.categories {
    list-style-type: "➡    ";
}

.option-buttons {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10px;
    max-width: 100%;
}

@media only screen and (min-width: 800px) {
    .option-buttons { max-width: 800px; }
}

.option-buttons > img {
    cursor: pointer;
    max-width: 100%;
    filter: drop-shadow(0px 0px 2.5px var(--primary));
}

.bubble {
    border-radius: 20px;
    background-color: var(--accent);
    padding: 5px;
    height: 100%;
}

.bubble img {
    display: block;
    text-align: left;
}
/*
.section-text {
    margin: 0 auto;
    text-align: center;
}
*/

@media (min-width: 1000px) {
    
    .bubble img {
        display: block;
        text-align: center;
        margin: 0 auto;
    }

}

.container {
    margin: 0 auto;
    max-width: min(90vw, 1400px);
}

.no-space {
    padding-block: 0rem;
    margin-block: 0rem;
}

.trial-entry {
    background: var(--accent);
    color: var(--background);
    border: 2px;
    border-radius: 5rem;
    outline: 2px solid var(--primary);
}

.txt-center {
    text-align: center;
}

.grid-2x1 {
    display: block;
    margin-bottom: 1rem;
}

.grid-2xx1 {
    display: block;
    margin-bottom: 1rem;
}

@media only screen and (min-width: 1000px) {
    .grid-2x1 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: "LT RT";
        gap: 1rem;
    }

    .grid-2xx1 {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(2, 1fr);
    }
}

.left-thing {
    grid-area: LT;
    background-color: var(--accent);
    color: var(--primary);
    margin-bottom: 1rem;
    border-radius: 1rem;
    padding-block: 1rem;
    padding-inline: 1rem;
}

.right-thing {
    grid-area: RT;
    background-color: var(--primary);
    color: var(--accent);
    margin-bottom: 1rem;
    border-radius: 1rem;
    padding-block: 1rem;
    padding-inline: 1rem;
}

/* Experimental */

.box {
    display: block;
    background: var(--background);
    position: relative;
    max-width: 80%;
    padding-inline: 15px;
    border-radius: 15px;
}

.box::before,
.box::after {
    content: "";
    border-radius: 30px;
    z-index: -1;
    position: absolute;
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    top: -15px;
    left: -15px;
    background-color: #ED1A35;
    background-size: 500%;
}

.box::after {
    filter: blur(30px);
}

.box *:not(button) {
    background-color: var(--background);
}

button, .btn {
    cursor: pointer;
}